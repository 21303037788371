var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--home--eth-blocks backgroundGrey py-15" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "div",
                    { staticStyle: { "max-width": "520px", margin: "0 auto" } },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.three,
                            expression: "three",
                          },
                        ],
                        staticClass: "d-lg-none d-md-none d-inline",
                        attrs: { width: "100%" },
                      }),
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.six,
                            expression: "six",
                          },
                        ],
                        staticClass: "d-lg-inline d-md-inline d-none",
                        attrs: { width: "100%" },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center justify-center",
                  class: _vm.$vuetify.breakpoint.smAndDown ? "mt-8" : "",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "max-width": "370px" } },
                    [
                      _c("div", { staticClass: "mew-subtitle mb-7" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("lokalise")(
                                _vm.$t("home.eth-blocks.title"),
                                "home.eth-blocks.title"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("lokalise")(
                                _vm.$t("home.eth-blocks.description"),
                                "home.eth-blocks.description"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("mew-button", {
                        staticClass: "mt-8",
                        attrs: {
                          "has-full-width": _vm.$vuetify.breakpoint.smAndDown,
                          "btn-size": "xlarge",
                          title: _vm._f("lokalise")(
                            _vm.$t("home.eth-blocks.btn-text"),
                            "home.eth-blocks.btn-text"
                          ),
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "EthBlocks",
                              params: {},
                            })
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }